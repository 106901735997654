var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("UpsellMenuItemCreate", {
    attrs: {
      upsellAddonItem: _vm.upsellItem,
      title: _vm.title,
      selectMoreItemsText: "Select Upsell Items",
      selectMoreExclusionaryItemsText: "Select Items to Exclude from Upsell",
      emptyText:
        "There does not appear to be any upsell items, please click below to add some.",
      emptyExclusionaryText:
        "There does not appear to be any excluded upsell items, please click below to add some.",
      leaveRoute: "upsell-menu-item",
      saveMethod: _vm.save,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }