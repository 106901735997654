<template>
  <UpsellMenuItemCreate
    :upsellAddonItem="upsellItem"
    :title="title"
    selectMoreItemsText="Select Upsell Items"
    selectMoreExclusionaryItemsText="Select Items to Exclude from Upsell"
    emptyText="There does not appear to be any upsell items, please click below to add some."
    emptyExclusionaryText="There does not appear to be any excluded upsell items, please click below to add some."
    leaveRoute="upsell-menu-item"
    :saveMethod="save"
  />
</template>

<script>
import UpsellMenuItemCreate from "components/upsell/upselladdon_menu_item_create.vue";
import { UpsellDataHelper } from "mixins/upsell.js";
import { mapActions, mapGetters } from "vuex";

export default {
    mixins: [UpsellDataHelper],
    components: {
        UpsellMenuItemCreate
    },
    computed: {
        upsellItem() {
            return {
                targetName: this.$route.query.targetName,
                TargetID: this.$route.query.targetID,
                LevelName: this.$route.query.type.toUpperCase(),
                LevelID: this.levelIDForType(this.$route.query.type),
                exclusionary: this.exclusionary
            };
        },
        title() {
            if (this.exclusionary) return `Excluded Upsell Items for ${this.$route.query.targetName}`
            return `${this.$route.query.targetName}`
        },
        exclusionary() {
            return this.$route.query.exclusionary === true || this.$route.query.exclusionary === 'true'
        }
    },
    methods: {
        levelIDForType(type) {
            if (type == "store") return 0;
            else if (type == "category") return 1;
            else if (type == "item") return 2;
            return -1;
        },
        save(dirtyPositions) {
            let waypointID = this.$route.params.store;

            return this.updateUpsellItem({
                waypointID,
                item: {
                    ...this.upsellItem,
                    positions: dirtyPositions
                }
            })
        },
        ...mapActions("UpsellStore", ["updateUpsellItem"])
    }
};
</script>

<style>
</style>
